
import { layoutAreaObjects, objectConnectionPoints } from '@icepanel/app-graphics'
import { DiagramContentPartial, ModelObject, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'ModelActionsMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop({ default: () => [] }) readonly objectIds!: string[]
  @Prop({ default: () => [] }) readonly modelObjectIds!: string[]
  @Prop({ default: () => [] }) readonly connectionIds!: string[]
  @Prop({ default: () => [] }) readonly modelConnectionIds!: string[]

  @Prop() readonly permission!: PermissionType

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get modelObjects () {
    return this.modelObjectIds.map(o => this.modelModule.objects[o])
  }

  get modelConnectionsDeleteEnabled () {
    if (this.currentDiagramContent && this.currentDiagram?.status === 'draft') {
      const modelConnectionIdsProposed = this.currentDiagramContent.tasksProposed
        .map(o => o.task.type === 'model-connection-create' ? o.task.id : undefined)
        .filter((o): o is string => !!o)

      return this.modelConnectionIds.every(o => modelConnectionIdsProposed.includes(o))
    } else {
      return true
    }
  }

  get modelObjectsDeleteEnabled () {
    if (this.currentDiagramContent && this.currentDiagram?.status === 'draft') {
      const modelObjectIdsProposed = this.currentDiagramContent.tasksProposed
        .map(o => o.task.type === 'model-object-create' ? o.task.id : undefined)
        .filter((o): o is string => !!o)

      return this.modelObjectIds.every(o => modelObjectIdsProposed.includes(o))
    } else {
      return true
    }
  }

  async deleteFromDiagram () {
    const currentDiagram = this.currentDiagram
    const currentDiagramContent = this.currentDiagramContent
    if (!currentDiagram || !currentDiagramContent) {
      return
    }

    const objects = { ...currentDiagramContent.objects }
    const connections = { ...currentDiagramContent.connections }

    const focusedObjectIds = this.objectIds?.filter((o): o is string => !!o).filter(o => o && objects[o]) || []

    const focusedConnectionIds = this.connectionIds?.filter((o): o is string => !!o).filter(o => connections[o]) || []
    const focusedObjectConnectionIds = Object
      .values(connections)
      .filter(o => (o.originId && focusedObjectIds.includes(o.originId)) || (o.targetId && focusedObjectIds.includes(o.targetId)))
      .map(o => o.id)

    const connectionIds = [...new Set([
      ...focusedConnectionIds,
      ...focusedObjectConnectionIds
    ])]

    if (!focusedObjectIds.length && !connectionIds.length) {
      return
    }

    const diagramProps: DiagramContentPartial = {}
    const revertDiagramProps: DiagramContentPartial = {}

    const deleteModelObjects: ModelObject[] = []

    focusedObjectIds.forEach(o => {
      const object = objects[o]
      const modelObject = object?.modelId ? this.modelModule.objects[object.modelId] : undefined
      if (
        modelObject &&
        !modelObject.caption &&
        !modelObject.childDiagramIds.length &&
        !modelObject.childIds.length &&
        !modelObject.description &&
        !modelObject.icon &&
        !Object.keys(modelObject.links).length &&
        !modelObject.name &&
        !modelObject.tagIds.length &&
        !modelObject.teamIds.length &&
        (Object.keys(modelObject.diagrams).length === 0 || (Object.keys(modelObject.diagrams).length === 1 && Object.keys(modelObject.diagrams).includes(currentDiagram.id))) &&
        !Object.values(this.modelModule.connections).some(o => o.originId === modelObject.id || o.targetId === modelObject.id)
      ) {
        deleteModelObjects.push(modelObject)
      }

      revertDiagramProps.objects = {
        ...revertDiagramProps.objects,
        $add: {
          ...revertDiagramProps.objects?.$add,
          [o]: object
        }
      }
      delete objects[o]
      diagramProps.objects = {
        ...diagramProps.objects,
        $remove: [
          ...diagramProps.objects?.$remove || [],
          o
        ]
      }
    })

    connectionIds.forEach(o => {
      revertDiagramProps.connections = {
        ...revertDiagramProps.connections,
        $add: {
          ...revertDiagramProps.connections?.$add,
          [o]: connections[o]
        }
      }
      delete connections[o]
      diagramProps.connections = {
        ...diagramProps.connections,
        $remove: [
          ...diagramProps.connections?.$remove || [],
          o
        ]
      }
    })

    const deleteModelObjectIds = deleteModelObjects.map(o => o.id)
    const deleteModelConnections = Object.values(this.modelModule.connections).filter(o => deleteModelObjectIds.includes(o.originId) || deleteModelObjectIds.includes(o.targetId))

    Object
      .entries(layoutAreaObjects(objects, this.modelModule.objects))
      .forEach(([id, rect]) => {
        revertDiagramProps.objects = {
          ...revertDiagramProps.objects,
          $update: {
            ...revertDiagramProps.objects?.$update,
            [id]: {
              ...revertDiagramProps.objects?.$update?.[id],
              height: objects[id].height,
              width: objects[id].width,
              x: objects[id].x,
              y: objects[id].y
            }
          }
        }
        objects[id] = {
          ...objects[id],
          ...rect
        }
        diagramProps.objects = {
          ...diagramProps.objects,
          $update: {
            ...diagramProps.objects?.$update,
            [id]: {
              ...diagramProps.objects?.$update?.[id],
              ...rect
            }
          }
        }
      })
    Object.entries(objectConnectionPoints(Object.values(objects), Object.values(connections))).forEach(([id, points]) => {
      revertDiagramProps.connections = {
        ...revertDiagramProps.connections,
        $update: {
          ...revertDiagramProps.connections?.$update,
          [id]: {
            ...revertDiagramProps.connections?.$update?.[id],
            points: connections[id].points
          }
        }
      }
      connections[id] = {
        ...connections[id],
        points
      }
      diagramProps.connections = {
        ...diagramProps.connections,
        $update: {
          ...diagramProps.connections?.$update,
          [id]: {
            ...diagramProps.connections?.$update?.[id],
            points
          }
        }
      }
    })

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    deleteModelObjects.forEach(o => {
      if (currentDiagram.status === 'draft') {
        revertTasks.push({
          id: currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: o.id,
                props: o,
                type: 'model-object-create'
              }]
            }
          },
          type: 'diagram-content-update'
        })
      } else {
        revertTasks.push({
          id: o.id,
          props: o,
          type: 'model-object-create'
        })
      }
    })
    deleteModelConnections.forEach(o => {
      if (currentDiagram.status === 'draft') {
        revertTasks.push({
          id: currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: o.id,
                props: o,
                type: 'model-connection-create'
              }]
            }
          },
          type: 'diagram-content-update'
        })
      } else {
        revertTasks.push({
          id: o.id,
          props: o,
          type: 'model-connection-create'
        })
      }
    })

    revertTasks.push({
      id: currentDiagram.id,
      props: revertDiagramProps,
      type: 'diagram-content-update'
    }, {
      route: this.$route,
      type: 'navigation'
    })

    if (currentDiagram.status === 'draft') {
      diagramProps.tasksProposed = {
        $append: []
      }
      deleteModelObjects.forEach(o => {
        diagramProps.tasksProposed?.$append?.push({
          id: o.id,
          type: 'model-object-delete'
        })
      })
      deleteModelConnections.forEach(o => {
        diagramProps.tasksProposed?.$append?.push({
          id: o.id,
          type: 'model-connection-delete'
        })
      })
    }

    const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagram.id, diagramProps)
    this.diagramModule.setDiagramContentVersion(diagramContent)
    this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramContentUpdate({
        diagramId: currentDiagram.id,
        landscapeId: this.currentLandscapeId,
        props: diagramContentUpdate,
        versionId: this.currentVersionId
      })
    })

    if (currentDiagram.status !== 'draft') {
      deleteModelObjects.forEach(o => {
        this.modelModule.removeObject(o.id)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectDelete({
            landscapeId: this.currentLandscapeId,
            objectId: o.id,
            versionId: this.currentVersionId
          })
        })
      })
      deleteModelConnections.forEach(o => {
        this.modelModule.removeConnection(o.id)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.connectionDelete({
            connectionId: o.id,
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          })
        })
      })
    }

    tasks.push({
      id: diagramContent.id,
      props: diagramContentUpdate,
      type: 'diagram-content-update'
    })

    deleteModelConnections.forEach(o => {
      if (currentDiagram.status === 'draft') {
        tasks.push({
          id: currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: o.id,
                type: 'model-connection-delete'
              }]
            }
          },
          type: 'diagram-content-update'
        })
      } else {
        tasks.push({
          id: o.id,
          type: 'model-connection-delete'
        })
      }
    })
    deleteModelObjects.forEach(o => {
      if (currentDiagram.status === 'draft') {
        tasks.push({
          id: currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: o.id,
                type: 'model-object-delete'
              }]
            }
          },
          type: 'diagram-content-update'
        })
      } else {
        tasks.push({
          id: o.id,
          type: 'model-object-delete'
        })
      }
    })

    tasks.push({
      route: this.$route,
      type: 'navigation'
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })

    const modelDeleteHelpSnackbar = parseInt(localStorage.getItem('modelDeleteHelpSnackbar') || '0')
    if (modelDeleteHelpSnackbar < 3) {
      localStorage.setItem('modelDeleteHelpSnackbar', `${modelDeleteHelpSnackbar + 1}`)
      this.alertModule.pushAlert({
        color: 'success',
        message: 'Removed from diagram. Tip: you can still access it in your model objects!'
      })
    }
  }
}
